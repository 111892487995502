import React, { useState, useCallback, useContext, lazy, Suspense } from 'react'
import { Link, useLocation, useNavigate, Routes, Route } from 'react-router-dom'
import { retry } from '../utils/CommonFunctions'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Loader from '../components/Loader'
import { useTheme } from '@mui/styles'
import styled from 'styled-components'
import { styled as styledComp } from '@mui/material/styles'
import { useLogout } from '../hooks/useLogout'
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { ColorModeContext } from '../context/ColorModeContext'
import logo from '../resources/logo.svg'
import back from '../resources/back.svg'
import contactus from '../resources/icons/contact-us.svg'
import feedback from '../resources/icons/feedback.svg'
import logouticon from '../resources/icons/log-out.svg'
import monthlyharvest from '../resources/icons/monthly-harvest.svg'
import myfarm from '../resources/icons/my-farm.svg'
import weather from '../resources/icons/weather.svg'
import settings from '../resources/icons/settings.svg'
import news from '../resources/news.svg'
import darkIcon from '../resources/icons/dark.svg'
import lightIcon from '../resources/icons/light.svg'
const TheFarm = lazy(() => retry(() => import('../pages/TheFarm')))
const EconomicWeather = lazy(() =>
  retry(() => import('../pages/EconomicWeather')),
)
const NewsAndIdeas = lazy(() => retry(() => import('../pages/NewsAndIdeas')))
const Settings = lazy(() => retry(() => import('../pages/Settings')))
const Feedback = lazy(() => retry(() => import('../pages/Feedback')))
const ContactUs = lazy(() => retry(() => import('../pages/ContactUs')))
const StockDetails = lazy(() => retry(() => import('../pages/StockDetails')))
const NewsDetails = lazy(() => retry(() => import('../pages/NewsDetails')))
const BlogsDetails = lazy(() => retry(() => import('../pages/BlogsDetails')))
const HarvestOverview = lazy(() =>
  retry(() => import('../pages/HarvestOverview')),
)
const InsiderTrading = lazy(() =>
  retry(() => import('../pages/InsiderTrading')),
)
const MutualFundHolders = lazy(() =>
  retry(() => import('../pages/MutualFundHolders')),
)
const EarningCallTranscript = lazy(() =>
  retry(() => import('../pages/EarningCallTranscript')),
)
const EtfHoldings = lazy(() => retry(() => import('../pages/EtfHoldings')))

const drawerWidth = 270

const Image = styled.img`
  margin: 2rem 0 2rem 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`

const StyledLink = styledComp(Link)`
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
  `

const SideBar2 = (props) => {
  const { window } = props
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [openLogout, setOpenLogut] = useState(false)
  const { logout, isLoading, error } = useLogout()
  const theme = useTheme()
  const colorMode = useContext(ColorModeContext)

  // handle user logout
  const handleLogout = () => {
    setOpenLogut(!openLogout)
  }

  const handleClose = () => {
    setOpenLogut(!openLogout)
  }

  // logout user
  const handleLogoutButton = useCallback(() => {
    logout()
  }, [logout])

  // open close drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleBackButton = () => {
    navigate(-1)
  }

  const drawer = (
    <div>
      <Toolbar>
        <Image src={logo} style={{ width: '100px' }} alt="logo" />
      </Toolbar>
      <List
        sx={{
          padding: 1,
          // selected and (selected + hover) states
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgba(0, 163, 255, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(0, 163, 255, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(45%) sepia(57%) saturate(2570%) hue-rotate(176deg) brightness(101%) contrast(107%)',
            },
          },
          // hover states
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'rgba(0, 163, 255, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(0, 163, 255, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(45%) sepia(57%) saturate(2570%) hue-rotate(176deg) brightness(101%) contrast(107%)',
            },
          },
        }}
      >
        {[
          { link: '/harvest-overview', text: 'Harvest Overview' },
          { link: '/the-farm', text: 'The Farm' },
          { link: '/economic-weather', text: 'Economic Weather' },
          { link: '/news-and-ideas', text: 'News and Ideas' },
        ].map((data, index) => (
          <StyledLink to={data.link} key={index}>
            <ListItem
              key={index}
              selected={data.link === pathname.split('/', 2).join('/')}
              sx={{ mt: 1.5, color: 'background.text' }}
              disablePadding
              // onClick={() => setMobileOpen(!mobileOpen)}
            >
              <ListItemButton>
                <ListItemIcon>
                  {
                    [
                      <img src={monthlyharvest} alt="Harvest Overview" />,
                      <img src={myfarm} alt="The Farm" />,
                      <img src={weather} alt="Economic Weather" />,
                      <img src={news} alt="News and Ideas" />,
                    ][index]
                  }
                </ListItemIcon>
                <ListItemText primary={data.text} />
              </ListItemButton>
            </ListItem>
          </StyledLink>
        ))}
      </List>
      <Divider />
      <List
        sx={{
          padding: 1,
          // selected and (selected + hover) states
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgba(0, 163, 255, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(0, 163, 255, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(45%) sepia(57%) saturate(2570%) hue-rotate(176deg) brightness(101%) contrast(107%)',
            },
          },
          // hover states
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'rgba(0, 163, 255, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(0, 163, 255, 1)',
            },
            ' & .MuiListItemIcon-root': {
              filter:
                'invert(45%) sepia(57%) saturate(2570%) hue-rotate(176deg) brightness(101%) contrast(107%)',
            },
          },
        }}
      >
        {[
          { link: '/settings', text: 'Settings' },
          { link: '/contact-us', text: 'Contact Us' },
          { link: '/feedback', text: 'Feedback' },
        ].map((data, index) => (
          <StyledLink to={data.link} key={index}>
            <ListItem
              key={index}
              selected={data.link === pathname}
              sx={{ mt: 1.5, color: 'background.text' }}
              disablePadding
              // onClick={() => setMobileOpen(!mobileOpen)}
            >
              <ListItemButton>
                <ListItemIcon>
                  {
                    [
                      <img src={settings} alt="Settings" />,
                      <img src={contactus} alt="Contact Us" />,
                      <img src={feedback} alt="Feedback" />,
                    ][index]
                  }
                </ListItemIcon>
                <ListItemText primary={data.text} />
              </ListItemButton>
            </ListItem>
          </StyledLink>
        ))}
      </List>
      <List
        sx={{
          padding: 1,
          mt: 5,
          // selected and (selected + hover) states
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgba(0, 163, 255, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(0, 163, 255, 1)',
            },
          },
          // hover states
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'rgba(0, 163, 255, 0.12)',
            borderRadius: '8px',
            '&': {
              color: 'rgba(0, 163, 255, 1)',
            },
          },
        }}
      >
        <div onClick={handleLogout}>
          <ListItem disablePadding onClick={handleDrawerToggle}>
            <ListItemButton>
              <ListItemIcon>
                <img src={logouticon} alt="Logout" />
              </ListItemIcon>
              <ListItemText
                sx={{ color: 'background.text' }}
                primary="Logout"
              />
            </ListItemButton>
          </ListItem>
        </div>
        <Dialog
          open={openLogout}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <Typography variant="caption">{error && error}</Typography>
          <DialogTitle
            sx={{ padding: '1rem 1.5rem', fontWeight: 'bold' }}
            id="alert-dialog-title"
          >
            LOGOUT
          </DialogTitle>
          <DialogContent sx={{ padding: '1rem 1.5rem' }}>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to Logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: '1rem 1.5rem' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              loading={isLoading}
              loadingIndicator={<CircularProgress color="primary" size={25} />}
              variant="contained"
              onClick={handleLogoutButton}
              autoFocus
            >
              Logout
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </List>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: 'transparent',
          boxShadow: 'none',
        }}
      >
        <Toolbar sx={{ backdropFilter: 'blur(20px)', py: 1 }}>
          <Container
            maxWidth="xl"
            sx={{
              paddingRight: 0,
              paddingLeft: '15px !important',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <HiOutlineMenuAlt4 />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box className="hides" id="backbutton">
                <Button
                  className="hidden"
                  aria-label="back"
                  onClick={handleBackButton}
                  startIcon={<img src={back} width="10" alt="back" />}
                >
                  Back
                </Button>
              </Box>
              <img className="mr hide" width={50} src={logo} alt="logo" />
              <Typography
                variant="h5"
                noWrap
                className="hidden"
                component="div"
                sx={{ fontWeight: 'bold' }}
                id="title"
              >
                {/* {pathname === '/the-farm'
                  ? 'The Farm'
                  : pathname === '/harvest-overview'
                  ? 'Harvest Overview'
                  : pathname === '/economic-weather'
                  ? 'Economic Weather'
                  : pathname === '/the-bin'
                  ? 'The Bin'
                  : pathname === '/settings'
                  ? 'Settings'
                  : pathname === '/about-us'
                  ? 'About Us'
                  : pathname.includes('/the-farm/')
                  ? 'The Farm'
                  : ''} */}
              </Typography>
            </Box>
            <input
              type="checkbox"
              checked={theme.palette.mode === 'dark' ? true : false}
              onClick={colorMode.toggleColorMode}
              // onChange={handleDarkMode}
              className="checkbox"
              id="checkbox"
              onChange={() => {}}
            />
            <label
              htmlFor="checkbox"
              className="label"
              style={{
                backgroundColor:
                  theme.palette.mode === 'light' ? '#1D242C' : '#0099FF',
                marginRight: '0',
              }}
            >
              <img
                src={darkIcon}
                style={{ paddingLeft: '5px', width: '15px' }}
                alt="darkmode"
              />
              <img
                src={lightIcon}
                style={{ paddingRight: '5px', width: '15px' }}
                alt="lightmode"
              />
              <div className="ball" />
            </label>
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          elevation={1}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: '15px !important',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Container maxWidth="lg">
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                exact
                path="/harvest-overview"
                element={<HarvestOverview />}
              />
              <Route exact path="/the-farm" element={<TheFarm />} />
              <Route
                exact
                path="/economic-weather"
                element={<EconomicWeather />}
              />
              <Route exact path="/news-and-ideas" element={<NewsAndIdeas />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/feedback" element={<Feedback />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route
                exact
                path="/the-farm/:symbol"
                element={<StockDetails />}
              />
              <Route
                exact
                path="/the-farm/:symbol/insider-trading"
                element={<InsiderTrading />}
              />
              <Route
                exact
                path="/the-farm/:symbol/mutual-fund-holders"
                element={<MutualFundHolders />}
              />
              <Route
                exact
                path="/the-farm/:symbol/earning-call-transcript"
                element={<EarningCallTranscript />}
              />
              <Route
                exact
                path="/the-farm/:symbol/etf-holdings"
                element={<EtfHoldings />}
              />
              <Route exact path="/news/:title" element={<NewsDetails />} />
              <Route exact path="/blogs/:title" element={<BlogsDetails />} />
            </Routes>
          </Suspense>
        </Container>
      </Box>
    </Box>
  )
}

SideBar2.propTypes = {
  window: PropTypes.func,
}

export default SideBar2
