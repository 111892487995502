import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuthContext()

  const logout = async (email, password) => {
    setIsLoading(true)
    setError(null)

    const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('user')).accessToken
        }`,
      },
    })
    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
    }
    if (response.ok) {
      // save the user to local storage
      localStorage.removeItem('user')

      // update the auth context
      dispatch({ type: 'LOGOUT' })

      // update loading state
      setIsLoading(false)
    }
  }

  return { logout, isLoading, error }
}
