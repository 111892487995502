import './App.scss'
import { lazy, useMemo, Suspense, useState, useEffect } from 'react'
import { retry } from './utils/CommonFunctions'
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import { CssBaseline, useMediaQuery } from '@mui/material'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import ScrollToTop from './components/ScrollToTop'
import { ColorModeContext } from './context/ColorModeContext'
import SideBar2 from './components/SideBar2'
import Loader from './components/Loader'
import Snowfall from 'react-snowfall'
import snowflake from './resources/snowflake.svg'
import axios from 'axios'
const Home = lazy(() => retry(() => import('./pages/Home')))

const Features = lazy(() => retry(() => import('./pages/Features')))
const Pricing = lazy(() => retry(() => import('./pages/Pricing')))
const SignUp = lazy(() => retry(() => import('./pages/SignUp')))
const SignIn = lazy(() => retry(() => import('./pages/SignIn')))
const ForgotPassword = lazy(() => retry(() => import('./pages/ForgotPassword')))

const App = () => {
  const { user } = useAuthContext()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const darkMode = localStorage.getItem('dark-mode')
  const [mode, setMode] = useState()
  const [snowfall, setSnowfall] = useState(false)

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('user'))?.accessToken
        }`,
      },
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/settings/admin`, config)
      .then((data) => {
        setSnowfall(data.data.data.isSnowEffect)
      })
      .catch((error) => {
        // remove user from storage
        localStorage.removeItem('user')
      })

    setMode(
      darkMode
        ? darkMode === 'light'
          ? 'dark'
          : 'light'
        : prefersDarkMode
        ? 'dark'
        : 'light',
    )
  }, [darkMode, prefersDarkMode])

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem('dark-mode', mode)
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    [mode],
  )

  // override mui theme
  let bgColor,
    primary,
    secondary,
    secondary2,
    boxShadowColor,
    bgPaper,
    bgDrawer,
    color,
    bg,
    gray,
    textColor,
    textColor2,
    titleBg,
    border
  switch (mode === 'light' ? false : true) {
    case false:
    default:
      bg = '#FFF'
      color = '#1C2A4C'
      textColor = '#1C2A4C'
      textColor2 = '#4C5B82'
      bgColor = '#f4f7ff'
      primary = '#00A3FF'
      gray = '#BBBBBB'
      secondary = '#8F9DC1'
      secondary2 = '#BBBBBB'
      boxShadowColor = '#DCE2F3'
      bgPaper = '#FFF'
      bgDrawer = '#FFF'
      titleBg = '#E8EDFA'
      border = '#DDE9FF'
      break

    case true:
      color = '#EEE'
      bgColor = '#0D1014'
      primary = '#00A3FF'
      textColor = '#FFFFFF'
      textColor2 = '#FFFFFF'
      secondary = '#5C7184'
      secondary2 = '#FFFFFF'
      bg = '#14181E'
      gray = '#BBBBBB'
      boxShadowColor = '#000000'
      bgPaper = '#14181E'
      bgDrawer = '#14181E'
      titleBg = '#1D242C'
      border = '#1D242C'
      break
  }

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          boxShadowColor: boxShadowColor,
          background: {
            default: bgColor,
            main: bgColor,
            light: bg,
            text: gray,
            bgcolor: titleBg,
          },
          primary: {
            main: primary,
            text: secondary2,
            contrastText: color,
            secondary: textColor2,
          },
          secondary: {
            main: secondary,
          },
        },
        typography: {
          fontFamily: 'lexend',
          color: color,
          caption: {
            fontSize: '0.8rem',
            color: secondary,
            fontWeight: 500,
          },
          subtitle1: {
            color: primary,
            fontWeight: 500,
          },
          body2: {
            color: secondary,
            fontSize: '16px',
          },
          h5: {
            fontWeight: 600,
            color: textColor,
          },
          h4: {
            fontWeight: 700,
            color: textColor,
          },
          h3: {
            color: color,
            fontWeight: 700,
          },
          h6: {
            color: color,
          },
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: `0px 2.5647px 7.05292px ${boxShadowColor}`,
                borderRadius: '20px',
                backgroundColor: bgPaper,
                backgroundImage: 'none',
              },
              elevation24: {
                boxShadow: 'none',
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                borderRadius: '0px 35px 35px 0px',
                border: 'none',
                backgroundColor: bgDrawer,
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                borderRadius: '15px !important',
                // padding: '10px 25px',
                backgroundColor: bgDrawer,
              },
              inputAdornedEnd: {
                marginRight: '-12px',
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: '#BBBBBB',
              },
            },
          },
          MuiAlert: {
            styleOverrides: {
              root: {
                boxShadow: `0px 3.22418px 8.86648px ${boxShadowColor}`,
                backgroundColor: bgPaper,
                borderRadius: '16px',
                color: color,
              },
            },
          },
          // MuiSvgIcon: {
          //   styleOverrides: {
          //     root: {
          //       color: '#ff0',
          //     },
          //   },
          // },
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'capitalize',
                borderRadius: '15px',
                padding: '14px 25px',
              },
              contained: {
                color: '#FFF',
              },
              containedError: {
                backgroundColor: '#F0380F',
              },
              outlined: {
                backgroundColor: 'rgba(0, 153, 255, 0.1)',
                border: 'none',
                '&:hover': {
                  border: 'none',
                  backgroundColor: 'rgba(0, 153, 255, 0.4)',
                },
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              select: {
                '&::before': {
                  borderColor: 'red',
                },
              },
            },
          },
          MuiTabPanel: {
            styleOverrides: {
              root: {
                padding: 0,
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                marginRight: '20px',
                boxShadow: `0px 3.22418px 8.86648px ${boxShadowColor}`,
                backgroundColor: bgPaper,
                borderRadius: '12px',
                padding: '1rem 1rem',
                color: color,
                border: 'none',
                '&.Mui-selected': {
                  color: '#FFF',
                  backgroundColor: primary,
                  borderRadius: '12px',
                  padding: '1rem 1rem',
                  border: 'none',
                },
              },
              fullWidth: {
                margin: 0,
                borderRadius: '8px',
                marginRight: '5px',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                '&:last-child': {
                  marginRight: 0,
                },
                '&.Mui-selected': {
                  backgroundColor: bg,
                  borderRadius: '8px',
                  color: color,
                  boxShadow:
                    '0px 2.84867px 7.59645px rgba(0, 0, 0, 0.12), 0px 2.84867px 0.949556px rgba(0, 0, 0, 0.04)',
                },
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              indicator: {
                display: 'none',
              },
              flexContainer: {
                padding: 3,
              },
            },
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                ':last-child': {
                  paddingBottom: '16px',
                },
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              labelPlacementStart: {
                margin: 0,
              },
              label: {
                fontWeight: 500,
                color: color,
              },
            },
          },
          MuiPopover: {
            styleOverrides: {
              paper: {
                boxShadow: `0px 3.27775px 9.01382px ${boxShadowColor}`,
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '0.8rem',
                backgroundColor: bgColor,
                color: color,
                boxShadow: `0px 3.27775px 9.01382px ${boxShadowColor}`,
                border: `1px solid ${boxShadowColor}`,
                padding: 10,
                minWidth: '100%',
                width: '400px',
                borderRadius: '15px',
              },
            },
          },
          MuiInputAdornment: {
            styleOverrides: {
              root: {
                '& p': {
                  color: primary,
                },
              },
            },
          },
          MuiCardMedia: {
            styleOverrides: {
              root: {
                objectFit: 'cover',
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              rounded: {
                '&:first-of-type': {
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                },
                '&:last-of-type': {
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                },
              },
              root: {
                '&:before': {
                  backgroundColor: 'rgba(143, 157, 193, 0.12)',
                },
              },
            },
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                borderWidth: '0.6px',
                borderColor: border,
              },
            },
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                // minWidth: '100%',
              },
            },
          },
          MuiRating: {
            styleOverrides: {
              iconFilled: {
                color: '#FFA500',
              },
            },
          },
        },
      }),
    [
      bg,
      bgColor,
      bgDrawer,
      bgPaper,
      border,
      boxShadowColor,
      color,
      gray,
      mode,
      primary,
      secondary,
      secondary2,
      textColor,
      textColor2,
      titleBg,
    ],
  )

  const snowflake1 = document.createElement('img')
  snowflake1.src = snowflake

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={responsiveFontSizes(theme)}>
          <CssBaseline />
          <Suspense fallback={<Loader />}>
            {snowfall && (
              <Snowfall
                images={[snowflake1]}
                snowflakeCount={25}
                speed={[0.5, 1]}
                wind={[-0.5, 1]}
                radius={[5, 15]}
              />
            )}
            <Routes>
              <Route
                exact
                path="/*"
                element={
                  user ? <SideBar2 /> : <Navigate replace to="/signin" />
                }
              />
              <Route
                exact
                path="/signup"
                element={
                  !user ? (
                    <SignUp />
                  ) : (
                    <Navigate replace to="/harvest-overview" />
                  )
                }
              />
              <Route
                exact
                path="/signin"
                element={
                  !user ? (
                    <SignIn />
                  ) : (
                    <Navigate replace to="/harvest-overview" />
                  )
                }
              />
              <Route
                exact
                path="/forget-password"
                element={
                  !user ? (
                    <ForgotPassword />
                  ) : (
                    <Navigate replace to="/harvest-overview" />
                  )
                }
              />
              <Route exact path="/" element={<Home />} />
              <Route exact path="/features" element={<Features />} />
              <Route exact path="/pricing" element={<Pricing />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </BrowserRouter>
  )
}

export default App
