import { Box, Card, CardContent } from '@mui/material'
import React from 'react'
import gif1 from '../resources/gif1.gif'

const Loader = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card>
        <CardContent sx={{ paddingBottom: '12px !important' }}>
          <img src={gif1} alt="logo" width="80" />
        </CardContent>
      </Card>
    </Box>
  )
}

export default Loader
